import React, { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { CCol, CRow } from '@coreui/react'
import { useParams } from 'react-router'
import MainLayout from '../layouts/MainLayout'
import { getTeams } from '../stores/teams/Selector'
import State from '../stores/State'
import { searchIssues } from '../stores/issues/Action'
import DaysRemaining from '../components/Shared/DaysRemaining'
import NextPIChart from '../components/NextPIPage/NextPIChart'
import TeamTabs from '../components/Shared/TeamTabs'
import Team from '../models/Team'
import NextPIBacklog from '../components/NextPIPage/NextPIBacklog'
import { getNextProductIncrements } from '../stores/productIncrements/Selector'
import ProductIncrementNotFound from '../components/NextPIPage/ProductIncrementNotFound'
import CommitmentPie from '../components/NextPIPage/CommitmentPie'
import NextPITeamProgress from '../components/NextPIPage/NextPITeamProgress'

const HomePage : React.FC = () => {
    const { productIncrementTag } = useParams()
    const teams = useSelector(getTeams)
    const productIncrement = useSelector(
        createSelector(
            [getNextProductIncrements],
            (productIncrements) => (
                productIncrements.find((pi) => pi.tag === productIncrementTag)
            ),
        ),
    )

    if (!productIncrement) {
        return (
            <>
                <ProductIncrementNotFound />
            </>
        )
    }

    const dispatch = useDispatch<ThunkDispatch<State, unknown, UnknownAction>>()

    useEffect(() => {
        dispatch(searchIssues({
            // eslint-disable-next-line max-len
            query: `issueType = Feature AND PI in('${productIncrement.tag}') AND Team in(${teams.map((team) => team.teamId).join(',')}) ORDER BY status ASC, Rank ASC`,
            autoRefresh: true,
            clear: true,
        })).catch(() => {
            console.error('Failed to load issues')
        })
    }, [productIncrementTag])

    return (
        <>
            <MainLayout>
                <CRow>
                    <CCol lg={3}>
                        <CRow>
                            <CCol lg={12}>
                                <DaysRemaining/>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg={12}>
                                <CommitmentPie />
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol lg={3}>
                        <NextPITeamProgress productIncrement={productIncrement}/>
                    </CCol>
                    <CCol lg={6}>
                        <NextPIChart></NextPIChart>
                    </CCol>
                </CRow>
                <h1>Backlogs</h1>
                <TeamTabs content={(team: Team): ReactNode => (
                    <NextPIBacklog team={team}></NextPIBacklog>
                )}></TeamTabs>
            </MainLayout>
        </>
    )
}

export default HomePage
