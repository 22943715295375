import React from 'react'
import { CProgressStacked, CProgress, CCard, CCardBody, CButton, CPopover, CBadge } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import { useSelector } from 'react-redux'
import { CIcon } from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'
import { getCurrentProductIncrement, getDaysRemaining, getNextProductIncrement } from '../../stores/productIncrements/Selector'

const CurrentPIProgress: React.FC = () => {
    const currentProgramIncrement = useSelector(getCurrentProductIncrement)
    const nextProgramIncrement = useSelector(getNextProductIncrement)
    const days = useSelector(getDaysRemaining)

    const progress = days.total > 0 ? (days.passed / days.total) * 100 : 0

    return (
        <CCard className="mb-4 bg-light" >
            <CCardBody>
                <CPopover
                    title="WTF are these colors ??"
                    placement="right"
                    content={(
                        <>
                            <p>
                                Soit <strong>A<sub>r</sub></strong> le pourcentage d'Avancement temporaire de la Release<br/>
                                <small className="text-muted"><strong>A<sub>r</sub></strong> = Temps écoulé / Temps total * 100</small>
                            </p>
                            <p>
                                Soit <strong>D<sub>e</sub></strong> le pourcentage de features engagées terminées d'une équipe<br/>
                                <small className="text-muted"><strong>D<sub>e</sub></strong> = Feature Done / Features engagées * 100</small>
                            </p>
                            <p style={{ marginBottom: '30px' }}>
                                Alors,
                                <ul>
                                    <li>Si <strong>D<sub>e</sub></strong> &gt;= <strong>A<sub>r</sub></strong> alors <CBadge color="success">Vert</CBadge></li>
                                    <li>Si <strong>D<sub>e</sub></strong> &gt;= <strong>A<sub>r</sub></strong>-10 alors <CBadge color="info">Bleu</CBadge></li>
                                    <li>Si <strong>D<sub>e</sub></strong> &gt;= <strong>A<sub>r</sub></strong>-25 alors <CBadge color="warning">Orange</CBadge></li>
                                    <li>Si <strong>D<sub>e</sub></strong> &lt; <strong>A<sub>r</sub></strong>-25 alors <CBadge color="danger">Rouge</CBadge></li>
                                </ul>
                            </p>
                            <CProgressStacked className="my-2 overflow-visible">
                                <CProgress color="danger" value={25}></CProgress>
                                <CProgress color="warning" value={20} progressBarClassName="overflow-visible">
                                    <span style={{ textAlign: 'left', position: 'relative', top: '-20px', left: '-15px' }}>A<sub>r</sub> - 25</span>
                                </CProgress>
                                <CProgress color="info" value={15} progressBarClassName="overflow-visible">
                                    <span style={{ textAlign: 'left', position: 'relative', top: '-20px', left: '-15px' }}>A<sub>r</sub> - 10</span>
                                </CProgress>
                                <CProgress color="success" value={40} progressBarClassName="overflow-visible">
                                    <span style={{ textAlign: 'left', position: 'relative', top: '-20px', left: '-5px' }}>A<sub>r</sub></span>
                                </CProgress>
                            </CProgressStacked>
                        </>
                    )}
                >
                    <CButton type="button" color="secondary" variant="ghost" className="float-end">
                        <CIcon icon={cilInfo}></CIcon>
                    </CButton>
                </CPopover>
                <div className="fs-4 fw-semibold">{days.remaining} days remaining</div>
                <div>Before PI {nextProgramIncrement.name}</div>
                <CProgressStacked style={{ height: '4px' }} className="my-2">
                    <CProgress height={4} color="danger" value={Math.max(0, progress - 25)} />
                    <CProgress height={4} color="warning" value={15} />
                    <CProgress height={4} color="info" value={10} />
                </CProgressStacked>
                <small className='text-body-secondary'>
                    PI {currentProgramIncrement.name} : {new Date(currentProgramIncrement.start).toLocaleDateString()} - {new Date(currentProgramIncrement.end).toLocaleDateString()}
                </small>
            </CCardBody>
        </CCard>
    )
}

export default CurrentPIProgress
