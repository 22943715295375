import { createReducer } from '@reduxjs/toolkit'
import Team from '../../models/Team'
import { shuffleTeams, updateTeam } from './Action'

const teams: Team[] = [
    { name: 'Team FT1', teamId: 'b9e8859b-f797-4b03-b484-dbe31cba7cd5', velocity: 6.5 },
    { name: 'Team FT2', teamId: 'c0fc291b-5641-44a3-8987-4cf64c823410', velocity: 8.5 },
    { name: 'Team FT3', teamId: '79ce7239-5e5a-433b-8ed9-25be219e7e92', velocity: 7 },
    { name: 'Team FT4', teamId: '462d30ef-d4f5-4d72-bd1e-d314c52ede4b', velocity: 8.5 },
    { name: 'Team FT5', teamId: '4a7c5208-38c1-4b51-a288-d91bde853077', velocity: 7.5 },
    { name: 'Team FT6', teamId: 'cb273d94-92b8-4b54-9fd7-00955b695adc', velocity: 7 },
    { name: 'Team CT1', teamId: '053689ad-87b7-4f9d-bfbf-57f830923d09', velocity: 7 },
    { name: 'Team CT2', teamId: 'f9f3799a-b112-4722-8ec0-c89e927c29b6', velocity: 8 },
    { name: 'Team TV', teamId: '565d4378-ec47-46b7-ad05-54a128097b25', velocity: 8 },
    { name: 'STEAM IT', teamId: '1a8c947e-dc29-45ef-9dbd-6d990adbe62c', velocity: 8.5, billable: false },
    { name: 'STEAM Infra', teamId: 'fb21661d-6987-4880-b803-33f9b262db24', velocity: 6.5, billable: false },
    { name: 'STEAM A', teamId: '59a91c42-b668-45ea-a47a-e86144f5008e', velocity: 9, billable: false },
    { name: 'TaHoma Pro Mobile', teamId: 'acda9084-886a-4deb-ae57-b6ec59470082', velocity: 8.5, billable: false },
    { name: 'Serve-e-Go', teamId: '1f39010d-e540-4707-8dc8-5e5e0aa1c0d4', velocity: 4, billable: false },
    { name: 'TaHoma App Mobile', teamId: '406120a4-ab1d-41a7-844d-bb83eb518c3d', velocity: 16, billable: false },
    { name: 'Design Team', teamId: '68bab36b-16c1-443e-bea0-65612e8f6d8a', velocity: 10, billable: false },
    { name: 'System Architect', teamId: '39f3378d-79b4-4b8a-b67b-223a3012e29c', velocity: 15, billable: false },
]

export type TeamsState = {
    items: Team[]
}

export const teamReducer = createReducer({ items: teams }, (builder) => {
    builder
        .addCase(updateTeam, (state: TeamsState, { payload }) => {
            state.items = state.items.map((team) => (team.teamId === payload.teamId ? payload : team))
        })
        .addCase(shuffleTeams, (state: TeamsState) => {
            const shuffledTeams = state.items

            for (let i = shuffledTeams.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledTeams[i], shuffledTeams[j]] = [shuffledTeams[j], shuffledTeams[i]]
            }

            state.items = shuffledTeams
        })
})
